<div class="container">
    <div class="row">
        <!--Start Single Features One -->
        <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12" *ngFor="let item of ctatext">
            <div class="features-one__single wow fadeInRight animated animated" data-wow-delay="00ms" data-wow-duration="1500ms">
                <div class="features-one__single-left">
                    <div class="features-one__single-left-icon">
                        <img [src]="item.icon" [alt]="item.title" />
                    </div>
                    <div class="features-one__single-left-text">
                        <h4><a routerLink="/about" [innerHtml]="item.text"></a></h4>
                    </div>
                </div>
                <div class="features-one__single-right">
                    <div class="features-one__single-right-btn">
                        <a routerLink="/about"><i class="fa fa-angle-double-right" aria-hidden="true"></i></a>
                    </div>
                </div>
            </div>
        </div>
        <!--End Single Features One -->
    </div>
</div>