<div class="container">
    <div class="section-title">
        <div class="section-title__tagline">
            <span class="left"></span>
            <h4>Trusted Partners</h4>
        </div>
        <h2 class="section-title__title">We’ve More Than 259+<br>Global Clients</h2>
    </div>
    <div class="row">
        <div class="col-xl-12">
            <div class="partner-one__content">
                <owl-carousel-o [options]="settings" class="partner-one__carousel list-unstyled">
                    <!-- Data -->
                    <ng-container *ngFor="let item of partners">
                        <ng-template carouselSlide>
                            <!--Start Single Partner One-->
                            <li class="partner-one__single">
                                <div class="partner-one__img">
                                    <a href="#"><img [src]="item.image" alt="img" /></a>
                                </div>
                            </li>
                            <!--End Single Partner One-->
                        </ng-template>
                    </ng-container>
                    <!-- Data -->
                </owl-carousel-o>
            </div>
        </div>
    </div>
</div>