<header class="main-header main-header--one  ">
    <div class="main-header-one__bottom">
        <div class="auto-container">
            <div class="main-header-one__bottom-inner clearfix" style="padding-right: 0">
                <nav class="main-menu main-menu--1">
                    <!-- menu -->
                    <div class="main-menu__inner" style="background: white">
                        <div class="left">
                            <div class="logo-box1">
<!--                                <div class="logo-box1_bg"-->
<!--                                    [style.background-image]="'url(assets/images/pattern/logo-box1-bg.png)'">-->
<!--                                </div>-->
                                <a routerLink="/">
                                    <img style="height: 40px" src="assets/images/resources/logo-1.png" alt="img">
                                </a>
                            </div>
                        </div>
                        <a href="javascript:void(0)" style="margin-right: 20px" class="mobile-nav__toggler" (click)="toggleNav()"><i class="fa fa-bars"></i></a>
                        <div class="middle">
                            <ul class="main-menu__list">
                                <li [ngClass]="item.child === true ? 'dropdown' : ''" *ngFor="let item of navigation">
                                    <a href="javascript:void(0)" *ngIf="item.child">{{item.linkText}}<span
                                            class="nav_arrow"></span></a>
                                    <a routerLink="{{item.link}}" *ngIf="!item.child">{{item.linkText}}</a>
                                    <ul class="sub-menu" *ngIf="item.child">
                                        <li [ngClass]="item.child === true ? 'dropdown' : ''"
                                            *ngFor="let item of item.submenu">
                                            <a href="javascript:void(0)" *ngIf="item.child">{{item.linkText}}<span
                                                    class="nav_arrow"></span></a>
                                            <a [routerLink]="item.link" *ngIf="!item.child && !item.query">{{item.linkText}}</a>
                                            <a [routerLink]="[item.link, item.query]" *ngIf="!item.child && item.query">{{item.linkText}}</a>
                                            <ul class="sub-menu" *ngIf="item.child">
                                                <li *ngFor="let item of item.submenu">
                                                    <a routerLink="{{item.link}}">{{item.linkText}}</a>
                                                </li>
                                            </ul>
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                            <div class="search-box">
                              <a href="http://t.me/uice_group" target="_blank" class="thm-btn telegram-button"><mat-icon color="black" svgIcon="telegram_black"></mat-icon></a>
                            </div>
                        </div>
                        <div class="right">
                            <div class="main-menu__right">
                              <div class="wow slideInUp animated" data-wow-delay="0.5s" data-wow-duration="1500ms">
                                <a href="https://auction.uice.com.ua/" target="_blank" class="thm-btn">Вхід в систему <i class="fa fa-angle-double-right" aria-hidden="true"></i></a>
                              </div>
                            </div>
                        </div>
                    </div>
                    <!-- menu -->
                </nav>
            </div>
        </div>
    </div>
</header>
<div class="stricky-header stricked-menu main-menu" [ngClass]="windowScrolled ? 'stricky-fixed':''">
    <div class="sticky-header__content">
        <!-- menu -->
        <div class="main-menu__inner">
            <div class="left">
                <div class="logo-box1">
<!--                    <div class="logo-box1_bg"-->
<!--                        [style.background-image]="'url(assets/images/pattern/logo-box1-bg.png)'">-->
<!--                    </div>-->
                    <a routerLink="/">
                        <img style="height: 40px" src="assets/images/resources/logo-1.png" alt="img">
                    </a>
                </div>
            </div>
            <a href="javascript:void(0)" class="mobile-nav__toggler" (click)="toggleNav()"><i class="fa fa-bars"></i></a>
            <div class="middle">
                <ul class="main-menu__list">
                    <li [ngClass]="item.child === true ? 'dropdown' : ''" *ngFor="let item of navigation">
                        <a href="javascript:void(0)" *ngIf="item.child">{{item.linkText}}<span
                                class="nav_arrow"></span></a>
                        <a routerLink="{{item.link}}" *ngIf="!item.child">{{item.linkText}}</a>
                        <ul class="sub-menu" *ngIf="item.child">
                            <li [ngClass]="item.child === true ? 'dropdown' : ''"
                                *ngFor="let item of item.submenu">
                                <a href="javascript:void(0)" *ngIf="item.child">{{item.linkText}}<span
                                        class="nav_arrow"></span></a>
                                <a routerLink="{{item.link}}" *ngIf="!item.child">{{item.linkText}}</a>
                                <ul class="sub-menu" *ngIf="item.child">
                                    <li *ngFor="let item2 of item.submenu">
                                      {{item | json}}
                                        <a (click)="goBlog(item2)">{{item2.linkText}}</a>
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    </li>
                </ul>
                <div class="search-box">
                    <a href="javascript:void(0)" class="main-menu__search search-toggler icon-search"
                        (click)="toggleSearch()">
                    </a>
                </div>
            </div>
            <div class="right">
                <div class="main-menu__right">
                    <div class="phone_number">
                        <div class="icon">
                            <span class="icon-agenda"></span>
                        </div>
<!--                        <div class="number">-->
<!--                            <p>Phone Us</p>-->
<!--                            <a href="tel:01682648101">+012 (34) 789</a>-->
<!--                        </div>-->
                    </div>
                </div>
            </div>
        </div>
        <!-- menu -->
    </div>
    <!-- /.sticky-header__content -->
</div><!-- /.stricky-header -->
<!-- Search -->
<div class="search-popup" [ngClass]="searchMethod ? 'active' : ''">
    <div class="search-popup__overlay search-toggler" (click)="toggleSearch()"></div>
    <app-search class="search-popup__content"></app-search>
</div>
<!-- Search -->
<!-- Mobilemenu -->
<div class="mobile-nav__wrapper" [ngClass]="navmethod ? 'expanded' : ''">
    <div class="mobile-nav__overlay mobile-nav__toggler" (click)="toggleNav()"></div>
    <!-- /.mobile-nav__overlay -->
    <div class="mobile-nav__content">
        <span class="mobile-nav__close mobile-nav__toggler" (click)="toggleNav()"><i class="fa fa-times"></i></span>
        <app-mobile-menu></app-mobile-menu>
    </div>
    <!-- /.mobile-nav__content -->
</div>
<!-- Mobilemenu -->
