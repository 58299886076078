<footer class="footer-one">
  <div class="container">
    <div class="footer-one__top">
      <!--            <div class="footer-one__bg" [style.background-image]="'url(assets/images/backgrounds/footer-v1-bg.png)'"></div>-->
      <div class="big-title">
        <h2>UICE</h2>
      </div>
      <div class="row">
        <!--Start Footer Widget Column-->
        <div class="col-xl-2 col-lg-2 col-md-6 wow animated fadeInUp" data-wow-delay="0.1s">
          <div class="footer-widget__column footer-widget__overview mar-top">
            <h3 class="footer-widget__title">Меню</h3>
            <ul class="footer-widget__overview-list list-unstyled">
              <li><a routerLink="/"><i class="fa fa-angle-double-right" aria-hidden="true"></i>Головна</a></li>
              <li><a routerLink="/members"><i class="fa fa-angle-double-right" aria-hidden="true"></i>Члени біржі</a></li>
              <li><a routerLink="/portfolio-grid"><i class="fa fa-angle-double-right"
                                                     aria-hidden="true"></i>Документи</a></li>
<!--              <li><a routerLink="/portfolio-grid"><i class="fa fa-angle-double-right" aria-hidden="true"></i>Реєстри</a>-->
<!--              </li>-->
<!--              <li><a routerLink="/portfolio-grid"><i class="fa fa-angle-double-right" aria-hidden="true"></i>Ринки</a>-->
<!--              </li>-->
            </ul>
          </div>
        </div>
        <!--End Footer Widget Column-->
        <!--Start Footer Widget Column-->
        <div class="col-xl-3 col-lg-3 col-md-6 wow animated fadeInUp" data-wow-delay="0.3s">
          <div class="footer-widget__column footer-widget__company mar-top">
            <h3 class="footer-widget__title">Компанія</h3>
            <ul class="footer-widget__company-list list-unstyled">
              <!--                            <li><a routerLink="/about"><i class="fa fa-angle-double-right" aria-hidden="true"></i>Про нас</a></li>-->
              <li><a routerLink="/company"><i class="fa fa-angle-double-right" aria-hidden="true"></i>Про нас</a></li>
              <!--                            <li><a routerLink="/career"><i class="fa fa-angle-double-right" aria-hidden="true"></i>Need a Career</a></li>-->
              <li><a routerLink="/contact"><i class="fa fa-angle-double-right" aria-hidden="true"></i>Контакти</a></li>
              <li><a routerLink="/team"><i class="fa fa-angle-double-right" aria-hidden="true"></i>Керівництво</a></li>
              <!--                            <li><a routerLink="/blog"><i class="fa fa-angle-double-right" aria-hidden="true"></i>Blog Post</a></li>-->
            </ul>
          </div>
        </div>
        <!--End Footer Widget Column-->
        <!--Start Footer Widget Column-->
        <div class="col-xl-3 col-lg-3 col-md-6 wow animated fadeInUp" data-wow-delay="0.5s">
          <div class="footer-widget__column footer-widget__newsletter mar-top">
            <h3 class="footer-widget__title">Контакти</h3>
            <div class="footer-contact-info">
              <ul class="list-unstyled">
                <li>
                  <div class="icon">
                    <i class="icon-letter" aria-hidden="true"></i>
                  </div>
                  <div class="text">
                    <a href="mailto:uice@uice.com.ua">uice@uice.com.ua</a>
                  </div>
                </li>
                <li>
                  <div class="icon">
                    <i class="icon-phone-call" aria-hidden="true"></i>
                  </div>
                  <div class="text">
                    <a href="tel:123456789">+38 (044) 461-54-30</a>
                  </div>
                </li>
              </ul>
            </div>
            <!--                        <h3 class="footer-widget__title">Newsletter</h3>-->
            <!--                        <p class="footer-widget__newsletter-text">Quis autem veleum prehendet <br>voluptate-->
            <!--                            velit esse</p>-->
            <!--                        <form class="footer-widget__newsletter-form">-->
            <!--                            <div class="footer-widget__newsletter-input-box">-->
            <!--                                <input type="email" placeholder="Email address" name="email" required>-->
            <!--                            </div>-->
            <!--                            <div class="newsletter__button">-->
            <!--                                <button type="submit" class="footer-btn"><span class="thm-btn">Subscription<i class="fa fa-angle-double-right" aria-hidden="true"></i></span></button>-->
            <!--                            </div>-->
            <!--                        </form>-->
          </div>
        </div>
        <!--End Footer Widget Column-->
        <!--Start Footer Widget Column-->
        <div class="col-xl-4 col-lg-4 col-md-6 wow animated fadeInUp" data-wow-delay="0.7s">
          <div class="footer-widget__column footer-widget__about mar-top">

            <h3 class="footer-widget__title">Реквізити</h3>

            <div class="footer-contact-info">
              <ul class="list-unstyled">
                <li>
                  <div class="icon">
                    <i class="icon-user user" aria-hidden="true"></i>
                  </div>
                  <div class="text">
                    <p><strong>Повне найменування</strong></p>
                    <p>Приватне акціонерне товариство «Українська міжбанківська
                      валютна біржа»</p>
                    <p><strong>Ідентифікаційний код</strong></p>
                    <p>22877057</p>
                  </div>
                </li>
                <li>
                  <div class="icon">
                    <i class="icon-pin map" aria-hidden="true"></i>
                  </div>
                  <div class="text">
                    <p><strong>Місце знаходження</strong></p>
                    <p>Україна, 04070, місто Київ, вулиця Межигірська, будинок 1</p>
                  </div>
                </li>
                <li>
                  <div class="icon">
                    <i class="icon-clock clock" aria-hidden="true"></i>
                  </div>
                  <div class="text">
                    <p><strong>Пн – Чт</strong> 9:00 – 13:00 13:45 – 18:00</p>
                    <p><strong>Пт</strong> 9:00 – 13:00 13:45 – 16:45</p>
                    <p><strong>Субота, неділя</strong> вихідний</p>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <!--End Footer Widget Column-->
      </div>
    </div>
    <!--Start Footer One Bottom-->
    <div class="footer-one__bottom">
      <div class="row">
        <div class="col-xl-12">
          <div class="footer-one__bottom-inner">
            <div class="footer-one__bottom-text text-center">
              <p>&copy;2022 <a href="#">UICE Group</a>. All Rights Reserved</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--End Footer One Bottom-->
  </div>
</footer>
<app-back-to-top></app-back-to-top>
