import { Component, OnInit } from '@angular/core';
import { HelperService } from '../../helper/helper.service';
import data from '../../data/navigation.json';
import {Router} from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent extends HelperService implements OnInit  {
  public navigation = data;
  constructor(private router: Router) {
    super();
  }

  ngOnInit(): void {
  }

  goBlog(item: any): void {
    this.router.navigate(
      [item.link],
      {queryParams: { category: item?.query }});
  }
}
