import {Component, OnInit} from '@angular/core';
import {Title} from '@angular/platform-browser';
import {Breadcrumb, BreadcrumbService} from 'angular-crumbs';
import {Location, LocationStrategy, PathLocationStrategy} from '@angular/common';
import {MatIconRegistry} from '@angular/material/icon';
import {DomSanitizer} from '@angular/platform-browser';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  providers: [
    Location, {
      provide: LocationStrategy,
      useClass: PathLocationStrategy
    }
  ]
})
export class AppComponent implements OnInit {
  constructor(private titleService: Title,
              private breadcrumbService: BreadcrumbService,
              private matIconRegistry: MatIconRegistry,
              private domSanitizer: DomSanitizer) {
    this.matIconRegistry.addSvgIcon(
      'telegram',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/telegram.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'telegram_black',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/telegram_black.svg')
    );
  }

  ngOnInit(): void {
    this.breadcrumbService.breadcrumbChanged.subscribe(crumbs => {
      this.titleService.setTitle(this.createTitle(crumbs));
    });
  }

  // tslint:disable-next-line:typedef
  onActivate(_event: any) {
    window.scroll(0, 0);
  }

  // tslint:disable-next-line:typedef
  private createTitle(routesCollection: Breadcrumb[]) {
    const title = 'Contract house';
    const titles = routesCollection.filter((route) => route.displayName);

    if (!titles.length) {
      return title;
    }

    const routeTitle = this.titlesToString(titles);
    return `${title}${routeTitle}`;
  }

  // tslint:disable-next-line:typedef
  private titlesToString(titles: any[]) {
    return titles.reduce((prev, curr) => {
      return `${prev} | ${curr.displayName}`;
    }, '');
  }
}
